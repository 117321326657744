import React, { useState, useEffect, useRef } from "react"
import { GlassBox, Box, Heading } from "../commonElements"
import { useSelector, useDispatch } from "react-redux"
import CardController from "./cardController"
import { updateEntriesState } from "../../redux/entries/actions"
import styled from "styled-components"
import FlipButtons from "./flipButtons"
import { usePersistValue } from "../../components/hook/usePersistValue"
import { useLocalEntriesContext } from "../contextHook/localEntries"
import { useSwipeable } from "react-swipeable"

const GuessBox = styled(Box)`
  padding: 3rem 0 2rem;
  margin-bottom: 1rem;
  @media ${props => props.theme.device.tablet} {
    max-height: 100%;
  }
`

const CardSimu = ({ cardType }) => {
  const { localEntries, setLocalEntries } = useLocalEntriesContext()
  const dispatch = useDispatch()
  const { value: cardData } = usePersistValue(
    state => state.entries[cardType],
    {}
  )
  const { value: cardIndex } = usePersistValue(
    state => state.entries[cardType].index,
    -1
  )
  const resultPersist = useSelector(state => state.entries[cardType].result)
  const { value: quantity } = usePersistValue(
    state => state.entries[cardType].quantity,
    0
  )
  const { value: sort } = usePersistValue(
    state => state.entries[cardType].sort,
    "R"
  )
  const [nextCardIndex, setNextCardIndex] = useState(-1)
  const [result, setResult] = useState([])

  const cardRef = useRef([])

  useEffect(() => {
    if (
      Object.keys(localEntries).length === 0 &&
      Object.keys(cardData).length > 0
    ) {
      // set only once when first load
      if (cardData.formula !== "" && cardData.list.length === 0) {
        // load one time only, initial formula value not yet set random number list
      } else {
        setLocalEntries({ ...cardData })
      }
    }
  }, [cardData])

  useEffect(() => {
    if (resultPersist !== result) {
      setResult(resultPersist)
      if (!isMatch(resultPersist, result)) {
        backToFrontCover()
      }
    }
  }, [resultPersist])

  useEffect(() => {
    backToFrontCover()
  }, [sort])

  useEffect(() => {
    if (sort === "LH" || sort === "HL" || cardIndex > quantity - 1) {
      backToFrontCover()
    }
  }, [quantity])

  const backToFrontCover = () => {
    dispatch(updateEntriesState(cardType, "index", -1))
    setNextCardIndex(-1)
  }

  // compare two arrays based on the name property and the order of elements
  const isMatch = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false // Different lengths, not a match.
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].name !== arr2[i].name) {
        return false // Names don't match, not a match.
      }
    }
    return true // All names match in the same order.
  }

  const swipeToFlip = eventData => {
    const { deltaY } = eventData
    eventData.event.preventDefault()
    if ((deltaY < -50 && deltaY > -301) || (deltaY > 50 && deltaY < 301)) {
      cardRef.current[0].handleFlipped(eventData.dir.toLowerCase())
    }
  }

  const handlers = useSwipeable({
    onSwipedDown: eventData => swipeToFlip(eventData),
    onSwipedUp: eventData => swipeToFlip(eventData),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: true,
  })

  return (
    <GlassBox
      alignItems="center"
      padding="3rem 0"
      flex="1"
      justifyContent="flex-start"
      width="100%"
      alignSelf="center"
      mb="0rem"
      style={{ overflow: "hidden" }}
    >
      <Box alignItems="center" padding="0 1rem">
        <Heading
          level={1}
          mb=".8rem"
          style={{ fontSize: "2.6rem", letterSpacing: "-0.5px" }}
        >
          NUMBER FLASHCARDS
        </Heading>
        <Heading
          level={2}
          style={{
            fontSize: "1.6rem",
            fontWeight: "normal",
            margin: "0",
            lineHeight: "1.5",
            textAlign: "center",
          }}
        >
          Learn numbers with flashcards by flipping
        </Heading>
      </Box>
      <GuessBox
        justifyContent="flex-start"
        alignItems="center"
        gap="3rem"
        {...handlers}
      >
        <Box
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="center"
          maxWidth="100%"
          positionBox="relative"
        >
          <CardController
            cardRef={cardRef}
            ref={el => (cardRef.current[0] = el)}
            cardType={cardType}
            nextCardIndex={nextCardIndex}
            setNextCardIndex={setNextCardIndex}
          />
        </Box>
        <FlipButtons
          cardType={cardType}
          shuffle={() => {
            cardRef.current[0].startShuffle()
          }}
          flip={index => setNextCardIndex(index)}
        />
      </GuessBox>
    </GlassBox>
  )
}

export default CardSimu
